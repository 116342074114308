import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class AddVoiceFile extends Component {
	constructor(props) {
		super(props);
		this.promptFileInput = React.createRef();
		let sitePath;
		let filePath;
		if (props.app.mode === 'dev') {
			sitePath = 'http://core-php.local';
			filePath = 'http://core-php.local/';
		} else {
			const protocol = window.location.protocol;
			const slashes = protocol.concat("//");
			sitePath = slashes.concat(window.location.hostname);

			//filePath = 'https://obdapi.noblestack.co/assets/voice/';
		}
		this.state = {
			promptFileType: 'newPromptFile',
			promptFileInput: this.promptFileInput,
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			promptUploaded: false,
			fileName: ''
		}

	}

	componentDidMount() {

	}


	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setUploadType = (type, uploadTypeKey) => {
		this.setState({
			[uploadTypeKey]: type,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			showModal: status
		});
	}

	onFileChangeHandler = event => {
		//if ( this.checkMimeType( event ) && this.checkFileSize( event ) ) {
		if (this.checkMimeType(event)) {

		}
	}


	getFileType = type => {

		switch(type){

			case 'audio/mpeg':
				return 'mp3';
				break;
			case 'audio/wav':
				return 'wav';
				break;
			default:
				return null;

		}

	}

	// Add Voice File

	handleAddVoiceFileSubmit = (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		const promptFile = formData.promptFileInput.current.files[0];
		const fileType = this.getFileType(promptFile.type);
		const fd = new FormData();
		fd.append('waveFile', promptFile);
		fd.append('userId', formData.userId);
		fd.append('fileName', formData.fileName);
		fd.append('promptCategory', formData.promptCategory);
		fd.append('fileType', fileType);

		return Fetcher.post(formData.apiPath + '/api/obd/promptupload', { method: 'POST', body: fd })
			.then(res => {
				let promptUploaded = false;
				if (res.message === 'Parameters Incorrect' || res.message === 'Voice file name already exists, Please choose another name') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					promptUploaded = true;
				}
				this.setState({
					promptUploaded: promptUploaded
				})
			})
			.catch(err => {
				console.log('Error in uploading Prompt File to Server', err);
				Notify.notify('Error in uploading Prompt File to Server');
			});
	}

	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	getFileNameFromUrl = (url) => {
		var n = url.lastIndexOf('/');
		return url.substring(n + 1);
	}

	checkMimeType = (event) => {
		let files = event.target.files
		let allowedTypes = ['text/plain', 'text/csv'];
		if (event.target.name === 'newContactsFile') {
			allowedTypes = ['text/plain', 'text/csv'];
		} else {
			allowedTypes = ['audio/wav', 'audio/mpeg'];
		}
		let err = [] // create empty array

		for (var x = 0; x < files.length; x++) {
			if (allowedTypes.every(type => files[x].type !== type)) {
				err[x] = files[x].type + ' is not a supported format\n';
				// assign message to array
			}
		};
		for (var z = 0; z < err.length; z++) { // loop create toast massage
			event.target.value = null;
		}
		return true;
	}

	checkFileSize = (event) => {
		let allowedSize = 1;
		if (event.target.name === 'newContactsFile') {
			allowedSize = 20;
		}

		let files = event.target.files
		let err = [];
		for (var x = 0; x < files.length; x++) {
			if (((files[x].size / 1024 / 1024).toFixed(2)) > allowedSize) {
				err[x] = files[x].size + ' is too large, please pick a smaller file\n';
			}
		};
		for (var z = 0; z < err.length; z++) {
			event.target.value = null;
		}
		return true;
	}



	render() {
		if (this.state.promptUploaded === true) {
			return <Redirect to='/voice-files' />
		}
		let submitButtonlabel = 'Upload File';

		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddVoiceFileSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Upload Voice File</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Label>Upload WAV or MP3 File</Form.Label>
										<Form.Group className="form-group"  controlId="prompt-file-upload">
											<Form.Control required name="newPromptFile" onChange={this.onFileChangeHandler} ref={this.promptFileInput} type="file" />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="trunk">
											<Form.Label>Select Category</Form.Label>
											<Form.Select required name="promptCategory" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Category</option>
												<option value="welcome">Welcome</option>
												<option value="menu">Menu</option>
												<option value="noinput">No Input</option>
												<option value="wronginput">Wrong Input</option>
												<option value="thanks">Thanks</option>
												<option value="noagent">No Agent</option>
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group"  controlId="file-name">
											<Form.Label>File Name</Form.Label>
											<Form.Control required name="fileName" onChange={e => this.setvalue(e)} value={this.state.fileName} type="text" placeholder="File Name" />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default AddVoiceFile;