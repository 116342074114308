import React ,{ useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';
import { Fetcher } from "../../Helpers/fetcher";
import './index.css';
import { Button, Accordion } from 'react-bootstrap';
const SideNav = (props) => {
	let urlPrefix;
	if (props.app.mode === 'dev') {
		urlPrefix = '/build';
	} else {
		urlPrefix = '';
	}
	const [moduleId, setModuleId] = useState();
	const [apiModuleId, setApiModuleId] = useState();
	const [dedicatedCliModuleId, setDedicatedCliModuleId] = useState();
	const [ivrId, setIvrId] = useState();
	const [inboundId, setInboundId] = useState();
	useEffect(() => {
    const apiPath = props.app.apiPath;
    const userId = props.app.userId;

    Fetcher.get(apiPath + "/api/obd/user/profile/" + userId)
      .then((res) => {
        console.log(res);
        let modules = res.modules;
        if (modules.length > 0) {
          for (let i = 0; i <= modules.length; i++) {
            if (modules[i].moduleId === 3) {
              setModuleId(3);
            }
			if(modules[i].moduleId === 4){
				setApiModuleId(4);
			}
			if(modules[i].moduleId === 5){
				setDedicatedCliModuleId(5);
			}
			if(modules[i].moduleId === 1){
				setIvrId(1);
			}
			if(modules[i].moduleId === 6){
				setInboundId(6);
			}
          }
        }
      })
      .catch((err) => {
        console.log("Error in fetching Users data", err);
      });
     });
	return (
		<div className="sidebar">
			<div class="sidebar__background"></div>
			<div className="scrollbar-container sidebar-nav">
				<a className="navbar-brand" href={props.app.siteUrl}>
					{props.app.logo && <img className="navbar-brand-full" src={props.app.logo} alt="Logo" />}
				</a>
				{props.app.role === 'ROLE_USER' && <ul className="nav">
					<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="mr-2 fa fa-dashboard"></i>Dashboard</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="mr-2 fa fa-history"></i>Credits History</Link></li>
					<li className='nav-group'>
						<Accordion defaultActiveKey="1">
						    {(ivrId === 1) && (
							<Accordion.Item eventKey="8">
								<Accordion.Header><i className="mr-2 fa fa-chip"></i> Custom IVR</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-custom-ivr`}><i className="mr-2 fa fa-plus"></i>Add Custom IVR</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/ivr-list`}><i className="mr-2 fa fa-list"></i>IVR List</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							)}
							<Accordion.Item eventKey="1">
								<Accordion.Header><i className="mr-2 fa fa-plus-circle"></i> Voice Campaigns</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-campaign`}><i className="mr-2 fa fa-plus"></i>Compose Campaign</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-campaign-summary`}><i className="mr-2 fa fa-list"></i>Live Summary</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/historical-voice-campaign-summary`}><i className="mr-2 fa fa-list"></i>Historical Summary</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/schedule-voice-campaign-summary`}><i className="mr-2 fa fa-list"></i>Schedule Summary</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="3">
								<Accordion.Header><i className="mr-2 fa fa-file"></i>Reports</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/download-report`}><i className="mr-2 fa fa-download"></i>Download Report</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/call-detail-records`}><i className="mr-2 fa fa-phone"></i>Call Detail Records</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header><i className="mr-2 fa fa-ban"></i>Voice Blacklist</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-blacklist`}><i className="mr-2 fa fa-plus"></i>Add to Blacklist</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/bulk-voice-blacklist`}><i className="mr-2 fa fa-plus"></i>Add Bulk Blacklist</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-blacklist`}><i className="mr-2 fa fa-list"></i>View Blacklist</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="5">
								<Accordion.Header><i className="mr-2 fa fa-music"></i> Voice Library</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-files`}><i className="mr-2 fa fa-plus"></i>Add Voice File</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="mr-2 fa fa-list"></i>View Voice Files</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							{(moduleId === 3) && (
							<Accordion.Item eventKey="13">
								<Accordion.Header><i className="mr-2 fa  fa-chain"></i> Manage Web Hook </Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
								  <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-webhook`}><i className="fa fa-plus-circle"></i> Add Webhook</Link></li>
					              <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/webhook-list`}><i className="fa fa-table"></i> View Webhook</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							)}
							{(apiModuleId === 4) && (
							<Accordion.Item eventKey="12">
								<Accordion.Header><i className="mr-2 fa fa-cogs"></i> Manage Api</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
								  <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-api-key`}><i className="fa fa-plus-circle"></i> Create Api Key </Link></li>
					              <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-api-key-list`}><i className="fa fa-table"></i> Api Key List </Link></li>
					              <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/api-key-summary-list`}><i className="fa fa-table"></i> Api Summary </Link></li>
					              <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/api-key-historical-summary-list`}><i className="fa fa-table"></i> Api Historical Summary </Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							)}
							{(inboundId === 6) && (
							<Accordion.Item eventKey="14">
								<Accordion.Header><i className="mr-2 fa fa-cogs"></i>Manage Inbound</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
								  <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-group-agent`}><i className="fa fa-plus-circle"></i> Create Agent Group</Link></li>
					              <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/group-agent-list`}><i className="fa fa-table"></i> Agent Group List</Link></li>
					              <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-inbound-campaign`}><i className="fa fa-table"></i> Inbound Campaign</Link></li>
					              <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/inbound-campaign-list`}><i className="fa fa-table"></i> Live Inbound Summary </Link></li>
								  <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/inbound-campaign-historical-list`}><i className="fa fa-table"></i> Historical Summary </Link></li>
								
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							)}
						</Accordion>
					</li>
				</ul>}

				{(props.app.role === 'ROLE_ADMIN') && <ul className="nav">
					<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
					<li className='nav-group'>
						<Accordion defaultActiveKey="0">
							<Accordion.Item eventKey="0">
							<Accordion.Header><i className="mr-2 fa fa-user"></i>Manage Users</Accordion.Header>
							<Accordion.Body>
								<ul className='nav-group-items'>							
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-user`}><i className="mr-2 fa fa-plus"></i>Create User </Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-list`}><i className="mr-2 fa fa-list"></i>User List</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="mr-2 fa fa-history"></i>Credits History</Link></li>
								    <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/reseller-summary`}><i className="mr-2 fa fa-list"></i>Reseller Summary</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							{ dedicatedCliModuleId === 5 && (
							<Accordion.Item eventKey="1">
							<Accordion.Header><i className="mr-2 fa fa-phone"></i>Manage CLI</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/cli-list`}><i className="mr-2 fa fa-list"></i>CLI List</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							)}
							<Accordion.Item eventKey="4">
								<Accordion.Header><i className="mr-2 fa fa-wifi"></i>Voice Plans</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-plan`}><i className="mr-2 fa fa-plus"></i>Add Voice Plan</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-plans`}><i className="mr-2 fa fa-list"></i>View Voice Plans</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="5">
								<Accordion.Header><i className="mr-2 fa fa-music"></i> Voice Library</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="mr-2 fa fa-list"></i>View Voice Files</Link></li>
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							{(inboundId === 6) && (
							<Accordion.Item eventKey="14">
								<Accordion.Header><i className="mr-2 fa fa-cogs"></i>Manage Inbound</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
					              <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/inbound-campaign-list`}><i className="fa fa-table"></i> Live Inbound Summary </Link></li>
								  <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/inbound-campaign-historical-list`}><i className="fa fa-table"></i> Historical Summary </Link></li>
								
								</ul>
								</Accordion.Body>
							</Accordion.Item>
							)}
						</Accordion>
					</li>
				</ul>}

				{(props.app.role === 'ROLE_SADMIN') && <ul className="nav">
				<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="mr-2 fa fa-dashboard"></i> Dashboard</Link></li>
				<li className='nav-group'>
					<Accordion defaultActiveKey="0">
						<Accordion.Item eventKey="0">
							<Accordion.Header><i className="mr-2 fa fa-user"></i>Manage Users</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>							
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-user`}><i className="mr-2 fa fa-plus"></i>Create User </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-list`}><i className="mr-2 fa fa-list"></i>User List</Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="mr-2 fa fa-history"></i>Credits History</Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/call-detail-records`}><i className="mr-2 fa fa-phone"></i>Call Detail Records</Link></li>
							    <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/reseller-summary`}><i className="mr-2 fa fa-list"></i>Reseller Summary</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="9">
						<Accordion.Header className="no-break-campaign"><i className="mr-2 fa fa-bullhorn"></i>Manage Campaign</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
							  <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-superadmin-campaign-summary`}><i className="fa fa-table"></i> Voice Campaign Summary</Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/download-report`}><i className="fa fa-table"></i> Download Report</Link></li>
							    <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/manage-campaigns-superadmin`}><i className="fa fa-bullhorn"></i> Manage Campaigns</Link></li>
								 <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/inbound-superadmin-campaign-summary`}><i className="fa fa-table"></i> Inbound Summary</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="1">
						<Accordion.Header><i className="mr-2 fa fa-phone"></i>Manage CLI</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
							<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/upload-cli`}><i className="mr-2 fa fa-upload"></i>Upload CLI</Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/allocate-cli`}><i className="mr-2 fa fa-tasks"></i>Allocate CLI </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/archived-cli`}><i className="mr-2 fa fa-archive"></i>Archived CLI List </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/cli-list`}><i className="mr-2 fa fa-list"></i>CLI List</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						{/* <Accordion.Item eventKey="14">
						<Accordion.Header><i className="mr-2 fa fa-headphones"></i><span className="small no-break-campaign">Manage Dedicated CLI</span></Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
							<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/upload-dedicated-cli`}><i className="mr-2 fa fa-upload"></i>Upload Dedicated CLI</Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/archived-dedicated-cli`}><i className="mr-2 fa fa-archive"></i><span className="small no-break-campaign">Archived Dedicated CLI List</span> </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/dedicated-cli-list`}><i className="mr-2 fa fa-list"></i>Dedicated CLI List</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item> */}
						<Accordion.Item eventKey="10">
						<Accordion.Header><i className="mr-2 fa fa-tasks"></i>Manage API CLI</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/upload-cli-api`}><i className="fa fa-plus-circle"></i> Upload Api CLI</Link></li>
					            <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/archived-api-cli`}><i className="fa fa-table"></i> Archived Api CLI List </Link></li>
					            <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/cli-api-list`}><i className="fa fa-table"></i> Api CLI List</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="11">
						<Accordion.Header><i className="mr-2 fa fa-key"></i>Manage Api Key</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
							    <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/api-key-summary-list`}><i className="fa fa-table"></i> Api Summary </Link></li>
					            <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/api-key-historical-summary-list`}><i className="fa fa-table"></i> Api Historical Summary </Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="6">
							<Accordion.Header><i className="mr-2 fa fa-users"></i>Manage Groups</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-group`}><i className="mr-2 fa fa-plus"></i>Create Group </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/group-list`}><i className="mr-2 fa fa-list"></i>Group List</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="7">
							<Accordion.Header><i className="mr-2 fa fa-map-marker"></i>Manage Locations</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-location`}><i className="mr-2 fa fa-plus"></i>Create Location </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/location-list`}><i className="mr-2 fa fa-list"></i>Location List</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="2">
							<Accordion.Header><i className="mr-2 fa fa-pencil"></i>Manage Dialers</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-dialer`}><i className="mr-2 fa fa-plus"></i>Create Dialer </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/dialer-list`}><i className="mr-2 fa fa-list"></i>Dialer List</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="3">
							<Accordion.Header><i className="mr-2 fa fa-phone"></i>Manage Trunks</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-trunk`}><i className="mr-2 fa fa-plus"></i>Create Trunk </Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/trunk-list`}><i className="mr-2 fa fa-list"></i>Trunk List</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="4">
							<Accordion.Header><i className="mr-2 fa fa-wifi"></i>Voice Plans</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-plan`}><i className="mr-2 fa fa-plus"></i>Add Voice Plan</Link></li>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-plans`}><i className="mr-2 fa fa-list"></i>View Voice Plans</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="5">
							<Accordion.Header><i className="mr-2 fa fa-music"></i>Voice Library</Accordion.Header>
							<Accordion.Body>
							<ul className='nav-group-items'>
								<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="mr-2 fa fa-check"></i>Prompts for Approval</Link></li>
							</ul>
							</Accordion.Body>
						</Accordion.Item>
						<Accordion.Item eventKey="14">
								<Accordion.Header><i className="mr-2 fa fa-ban"></i>Blacklist-DND</Accordion.Header>
								<Accordion.Body>
								<ul className='nav-group-items'>
				
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-blacklist-dnd`}>Manage Blacklist-DND</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/bulk-blacklist-dnd`}>Manage Bulk Blacklist-DND</Link></li>
									<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/search-blacklist-dnd`}>Search Blacklist-DND</Link></li>
								</ul>
								</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</li>	
				</ul>}

			</div>
		</div>
	);

}

export default SideNav;
